import { useEffect, useRef } from "react";

const StockMarketWidget = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      colorTheme: "light",
      dateRange: "1M",
      exchange: "BSE",
      showChart: true,
      locale: "en",
      width: "300",
      height: "450",
      largeChartUrl: "",
      isTransparent: false,
      showSymbolLogo: false,
      showFloatingTooltip: true,
      plotLineColorGrowing: "rgba(103, 83, 255, 1)",
      plotLineColorFalling: "rgba(103, 83, 255, 1)",
      gridLineColor: "rgba(46, 46, 46, 0)",
      scaleFontColor: "rgba(15, 15, 15, 1)",
      belowLineFillColorGrowing: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorFalling: "rgba(41, 98, 255, 0.12)",
      belowLineFillColorGrowingBottom: "rgba(41, 98, 255, 0)",
      belowLineFillColorFallingBottom: "rgba(41, 98, 255, 0)",
      symbolActiveColor: "rgba(41, 98, 255, 0.12)"
    });
    
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
  }, []);
  return (
    <div className="p-4 border rounded-lg border-[#c1c3c7] flex flex-col gap-3" ref={containerRef}>
    </div>
  );
};

export default StockMarketWidget
