import { heIL } from "@mui/x-data-grid";
import { useEffect, useRef } from "react";

const StockHeatmapWidget = () => {
  const containerRef = useRef(null);

useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-stock-heatmap.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      exchanges: ["BSE"],
      dataSource: "SENSEX",
      grouping: "sector",
      blockSize: "market_cap_basic",
      blockColor: "change",
      locale: "en",
      symbolUrl: "",
      colorTheme: "light",
      hasTopBar: true,
      isDataSetEnabled: true,
      isZoomEnabled: false,
      hasSymbolTooltip: true,
      isMonoSize: true,
      width: "100%",
      height: "340",
    });
    
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
  }, []);

  return (
    <div className="p-4 border rounded-lg h-[] border-[#c1c3c7] flex flex-col gap-3">
    <div className="tradingview-widget-container  " ref={containerRef}>
     
    </div>
    </div>
  );
};

export default StockHeatmapWidget;
