import { Logout } from "@mui/icons-material";
import { Collapse, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "../../Styles/CustomStyles.css";
import { createAxiosInstance } from "../../Utils/axiosConfig";
import AlgoAppLogo from "../../assets/tradebuddys_logo.png";
import NavItem from "./NavItem";

function NavigationDrawer() {
    const [toggleNav, setToggleNav] = useState(false);
    const [userDetails, setUserDetails] = useState(undefined);
    const navigate = useNavigate();



    const handleLogoutClick = async () => {
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.post('auth/logout/', { refresh: JSON.parse(localStorage.getItem("userAuthToken"))?.userToken?.refreshToken });

            if(response.status === 200) {
                localStorage.clear();
                navigate('/login')
            }
        } catch (err) {
            // implement new error mechanism here
            console.log(err.message);
        }
    }

    useEffect(() => {
        var _userDetails = JSON.parse(localStorage.getItem("userDetails"));
        setUserDetails(_userDetails);
    }, []);

    return (
        <div className="navigation-drawer bg-primaryBg h-screen " onMouseLeave={() => setToggleNav(false)} onMouseEnter={() => setToggleNav(true)}>
            <Collapse orientation="horizontal" in={toggleNav} collapsedSize={72} className="!h-screen ">
                <div className="py-8 px-4 flex flex-col gap-6 h-full">
                    <div className="pl-2 flex gap-4 items-center hover:cursor-pointer" onClick={() => navigate('/home')}>
                        <img width={32} height={32} src={AlgoAppLogo} alt="Logo" />
                        {toggleNav && <h1 className="text-lg font-medium text-white">Tradebuddys</h1>}
                    </div>
                    <NavLink to='/home'>
                        {({ isActive }) => {
                            return (
                                <NavItem iconSvg={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M18 20V10M12 20V4M6 20V14" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>} collapsed={toggleNav} menuItem={'Dashboard'} active={isActive} />
                            )
                        }}
                    </NavLink>

                    <NavLink to='/strategies'>
                        {({ isActive }) => {
                            return (
                                <NavItem iconSvg={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M2 17L12 22L22 17M2 12L12 17L22 12M12 2L2 7L12 12L22 7L12 2Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                } collapsed={toggleNav} menuItem={'Strategies'} active={isActive} />
                            )
                        }}
                    </NavLink>


                    <NavLink to='/brokerAccountsSetup'>
                        {({ isActive }) => {
                            return (
                                <NavItem iconSvg={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                } collapsed={toggleNav} menuItem={'Brokers'} active={isActive} />
                            )
                        }}
                    </NavLink>

                    <NavLink to='/reports'>
                        {({ isActive }) => {
                            return (
                                <NavItem iconSvg={
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20M4 19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H20V2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V19.5Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                } collapsed={toggleNav} menuItem={'Reports'} active={isActive} />
                            )
                        }}
                    </NavLink>

                    <div className="mt-auto">
                        <NavLink to='/settings'>
                            {({ isActive }) => {
                                return (
                                    <NavItem iconSvg={
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M18 20V10M12 20V4M6 20V14" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    } collapsed={toggleNav} menuItem={'Support'} active={isActive} />
                                )
                            }}
                        </NavLink>
                        <hr />
                    </div>
                    <div className="flex items-center gap-4 hover:cursor-pointer" onClick={() => navigate('/settings')}>
                        {toggleNav && <div className="flex flex-col">
                            <h1 className="text-sm font-semibold text-white">{userDetails?.first_name} {userDetails?.last_name}</h1>
                            <h1 className="text-sm font-normal text-white">{userDetails?.email}</h1>
                        </div>}
                        <IconButton onClick={handleLogoutClick}>
                            <Logout sx={{ color: "#98A2B3" }} />  
                        </IconButton>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default NavigationDrawer;
