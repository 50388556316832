import React from "react";
import PageNotFound from "./Pages/PageNotFound";
import { Route, Routes } from "react-router-dom";
import Index from "./Pages/Index";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import DashboardPage from "./Pages/DashboardPage";
import AddFinvasiaBroker from "./Pages/AddFinvasiaBroker";
import RequireAuth from './Utils/RequireAuth';
import DefaultLayout from "./Pages/DefaultLayout";
import AddZerodhaBroker from "./Pages/AddZerodhaBroker";
import AddAngelBroker from "./Pages/AddAngelBroker";
import Strategies from "./Pages/Strategies";
import AddStrategy from "./Pages/AddStrategy";
import SettingsPage from "./Pages/SettingsPage";
import ActiveSession from "./Utils/ActiveSession";
import BrokerAccountsSetup from "./Pages/BrokerAccountsSetup";
import { ThemeProvider } from "@emotion/react";
import colorTheme from "./Utils/theme";
import ZerodhaRedirectPage from "./Pages/ZerodhaRedirectPage";
import Reports from "./Pages/Reports";
import About from "./Pages/About";
import StaticPages from "./Pages/StaticPages";
import WebSocketProvider from "./Utils/WebSocketProvider";
import AddEtfStrategy from "./Pages/AddEtfStrategy";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";

function RoutesSetup() {
  return (
    <Routes>
        <Route path="/" element={<StaticPages />}>
            <Route index element={<Index />} />
            <Route path="about" element={<About />} />
        </Route>

        {/* Login routes */}
        <Route path="login" element={<ThemeProvider theme={colorTheme}><Login /></ThemeProvider>} />

        {/* Auth routes */}
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/password-reset-confirm/:uid/:token" element={<ResetPassword />} />

        {/* Signup routes */}
        <Route path="signup" element={<ThemeProvider theme={colorTheme}><SignUp /></ThemeProvider>} />

        <Route path="/" element={<DefaultLayout />}>
            <Route element={<RequireAuth />}>
                <Route element={<ActiveSession />}>
                    <Route element={<WebSocketProvider />}>
                        {/* Dashboard routes */}
                        <Route path="home" element={<DashboardPage />} />
                        {/* Broker setup routes */}
                        <Route path="addBroker">
                            <Route path="finvasia" element={<AddFinvasiaBroker />} />
                            <Route path="zerodha" element={<AddZerodhaBroker />} />
                            <Route path="angelist" element={<AddAngelBroker />} />
                        </Route>
                        <Route path="brokerAccountsSetup" element={<BrokerAccountsSetup />} />
                        {/* Strategy Routes */}
                        <Route path="strategies">
                            <Route index element={<Strategies />} />
                            <Route path="addStrategy" element={<AddStrategy />} />
                            <Route path="addStrategy/etf" element={<AddEtfStrategy />} />
                        </Route>
                        {/* Reports routes */}
                        <Route path="reports" element={<Reports />} />
                        {/* Settings routes */}
                        <Route path="settings" element={<SettingsPage />} />
                    </Route>
                </Route>
            </Route>
        </Route>

        <Route element={<RequireAuth />}>
            <Route path="zerodha/redirect/:brokerId" element={<ZerodhaRedirectPage />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default RoutesSetup;
