import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  Paper, 
  Typography, 
  Box,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { createAxiosInstance } from '../Utils/axiosConfig';

const ResetPassword = () => {
  const [passwords, setPasswords] = useState({
    new_password1: '',
    new_password2: '',
  });
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tokenValid, setTokenValid] = useState(true); 
  

  const { uid, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      if (!uid || !token) {
        setTokenValid(false);
        setIsError(true);
        setMessage('Invalid reset link. Please request a new one.');
        setOpenSnackbar(true);
        return;
      }
      
      setTokenValid(true);
    };
    
    verifyToken();
  }, [uid, token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwords.new_password1 !== passwords.new_password2) {
      setIsError(true);
      setMessage("Passwords don't match");
      setOpenSnackbar(true);
      return;
    }
    if (passwords.new_password1.length < 8) {
      setIsError(true);
      setMessage("Password must be at least 8 characters long");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    try {
      const axios = createAxiosInstance(false);
      
  
      const response = await axios.post(`/auth/password-reset-confirm/${uid}/${token}/`, {
        new_password1: passwords.new_password1,
        new_password2: passwords.new_password2,
        uid: uid,  
        token: token 
      });
      
      setIsError(false);
      setMessage('Password reset successfully. Redirecting to login...');
      setOpenSnackbar(true);
      
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setIsError(true);
      setMessage(
        error.response?.data?.detail || 
        error.response?.data?.message || 
        'Failed to reset password. Please try again.'
      );
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  if (!tokenValid) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f5f5f5">
        <Paper elevation={3} sx={{ p: 4, maxWidth: 400, textAlign: 'center' }}>
          <Typography variant="h6" color="error">Invalid or Expired Link</Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/auth/forgot-password')} sx={{ mt: 2 }}>
            Request New Reset Link
          </Button>
        </Paper>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f5f5f5">
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400 }}>
        <Typography variant="h5" gutterBottom align="center">Reset Password</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="New Password"
            type="password"
            value={passwords.new_password1}
            onChange={(e) => setPasswords({...passwords, new_password1: e.target.value})}
            margin="normal"
            required
            disabled={loading}
            helperText="Password must be at least 8 characters long"
          />
          <TextField
            fullWidth
            label="Confirm New Password"
            type="password"
            value={passwords.new_password2}
            onChange={(e) => setPasswords({...passwords, new_password2: e.target.value})}
            margin="normal"
            required
            disabled={loading}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3, mb: 2, height: 48 }} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Reset Password'}
          </Button>
        </form>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
          <Alert severity={isError ? "error" : "success"} onClose={() => setOpenSnackbar(false)}>
            {message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default ResetPassword;