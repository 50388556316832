import React from "react";
import { Outlet } from "react-router-dom";
import NavigationDrawer from "../components/sidebar-navigation/NavigationDrawer";
import "../Styles/CustomStyles.css";
import { ThemeProvider } from "@mui/material";
import colorTheme from "../Utils/theme";
import LayoutWrapper from "../Utils/LayoutWrapper";

function DefaultLayout() {
  return (
    <div className="flex h-screen overflow-hidden">
            <div className="flex-shrink-0">
                <NavigationDrawer />
            </div>
            <div className="flex-grow overflow-auto">
                <ThemeProvider theme={colorTheme}>
                    <LayoutWrapper>
                        <Outlet />
                    </LayoutWrapper>
                </ThemeProvider>
            </div>
        </div>
  );
}

export default DefaultLayout;
