import React, { useState, useEffect } from 'react';
import { 
  TextField, 
  Button, 
  Paper, 
  Typography, 
  Box,
  Snackbar,
  Alert,
  CircularProgress
} from '@mui/material';
import { createAxiosInstance } from '../Utils/axiosConfig';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsButtonDisabled(true); // Disable the button
    try {
      const axios = createAxiosInstance(false);
      await axios.post('/auth/password-reset/', {
        email: email
      });
      setIsError(false);
      setMessage('Password reset link has been sent to your email. Please check your inbox.');
      setOpenSnackbar(true);
      setEmail(''); // Clear email field after success
    } catch (error) {
      setIsError(true);
      setMessage(error.response?.data?.message || 'Failed to send reset email. Please try again.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
      setTimeout(() => {
        setIsButtonDisabled(false); // Enable the button after 15 seconds
      }, 15000);
    }
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgcolor="#f5f5f5"
    >
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%', m: 2 }}>
        <Typography variant="h5" gutterBottom align="center">
          Forgot Password
        </Typography>
        <Typography color="textSecondary" sx={{ mb: 3 }} align="center">
          Enter your email address to receive a password reset link
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            disabled={loading}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3, mb: 2, height: 48 }}
            disabled={loading || isButtonDisabled} // Disable button when loading or disabled for 15 seconds
          >
            {loading ? <CircularProgress size={24} /> : 'Send Reset Link'}
          </Button>
        </form>
        <Snackbar 
          open={openSnackbar} 
          autoHideDuration={6000} 
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={isError ? "error" : "success"} onClose={() => setOpenSnackbar(false)}>
            {message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default ForgotPassword;
