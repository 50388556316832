import { useEffect, useRef } from "react";

const TickerWidget = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-tickers.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        { description: "SENSEX", proName: "BSE:SENSEX" },
        { description: "HDFCBANK", proName: "BSE:HDFCBANK" },
        { description: "RELIANCE", proName: "BSE:RELIANCE" }
      ],
      isTransparent: false,
      showSymbolLogo: false,
      colorTheme: "light",
      locale: "en"
    });
    
    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
  }, []);


  return (
    <div className=" border border-[#c1c3c7] flex flex-col gap-6 rounded-b-md" ref={containerRef}>
    
      </div>

  );
};

export default TickerWidget
